import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// css
import "./styled.css";
// images
import Logo from "../../../assets/images/profuse/logo.png";
// componentes
import PageQuestions from "./pageQuestions";
import PageProducts from "./pageProducts";
import GetName from "./getName";
// service
import { postSurvey } from "../../../services/survey";

export default function Profuse() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allAnswer, setAllAnswer] = useState({});
  // modal
  const [modalName, setModalName] = useState(false);

  async function submitForm(allAnswer) {
    setLoading(true);
    if (user.idEvent && user.idPart) {
      await sendData(allAnswer.question1);
      await sendData(allAnswer.question2);
      await sendData(allAnswer.question3);
      await sendData(allAnswer.question4);
      await sendData(allAnswer.question5);
      await sendData(allAnswer.question6);
    }

    setAllAnswer({
      pele: [allAnswer.question1.answer],
      preocupacao: [allAnswer.question2.answer],
      estetico: [allAnswer.question3.answer],
      condicoes: allAnswer.question4.answer,
      capilar: [allAnswer.question5.answer],
      maquiagem: [allAnswer.question6.answer],
    });

    setStep(2);
  }

  async function sendData(question) {
    var answerFormated = "";

    if (question.type === "one") {
      answerFormated = question.answer;
    } else {
      answerFormated = question.answer.map((ans) => ans).join(", ");
    }

    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: question.title,
      answer: answerFormated,
      hit: true,
    };
    await postSurvey(data);
  }

  useEffect(() => {
    if (window.location.search !== "") {
      setUser({
        idEvent: searchParams.get("idEvent"),
        idPart: searchParams.get("idPart"),
        name: searchParams.get("name"),
      });
    } else setModalName(true);
  }, []);

  return (
    <>
      {modalName && (
        <GetName
          isOpen={modalName}
          toggle={() => setModalName(false)}
          setUser={setUser}
        />
      )}

      <section className="section-profuse">
        <div className="div-profuse-header">
          <img src={Logo} className="img-profuse-logo-header" />
        </div>

        <div className="div-profuse-body">
          {step === 1 && (
            <PageQuestions submitForm={submitForm} loading={loading} />
          )}
          {step === 2 && <PageProducts allAnswer={allAnswer} user={user} />}
        </div>
      </section>
    </>
  );
}

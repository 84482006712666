import React, { useState, useEffect } from "react";
import "./styled.css";

export default function Question(props) {
  const {
    e,
    i,
    saveAnswer,
    stepQuestions,
    setStepQuestions,
    sendData,
    allAnswer,
    loadingSend,
  } = props;
  const [answer, setAnswer] = useState(e.type === "multi" ? [] : "");
  const [loading, setLoading] = useState(true);

  function formatAnswer(option, checked) {
    var allAnswerSelected = answer;

    if (checked) {
      allAnswerSelected.push(option);
    } else {
      allAnswerSelected = answer.filter((e) => e !== option);
    }

    setAnswer(allAnswerSelected);
    saveAnswer({
      title: e.title,
      answer: allAnswerSelected,
      index: i + 1,
      type: e.type,
    });
  }

  function formatAnswerRadio(option) {
    setAnswer(option);
    saveAnswer({
      title: e.title,
      answer: option,
      index: i + 1,
      type: e.type,
    });
  }

  useEffect(() => {
    if (allAnswer) {
      for (const [key, value] of Object.entries(allAnswer)) {
        if (value.title === e.title) {
          setAnswer(value.answer);
        }
      }
    }

    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <>
      <h4 style={{ marginBottom: 20, textAlign: "center" }}>{e.title}</h4>

      {e.answers.map((ans, index) => {
        if (e.type === "multi") {
          return (
            <div className="form-check mb-3" key={`idCheck1-${index}`}>
              <input
                className="form-check-input"
                defaultChecked={
                  answer.find((item) => item === ans) ? true : false
                }
                type="checkbox"
                id={`${e.title}${index}`}
                onClick={(check) => formatAnswer(ans, check.target.checked)}
                disabled={
                  (answer.find(
                    (option) => option === "NENHUMA DAS CONDIÇÕES"
                  ) &&
                    ans !== "NENHUMA DAS CONDIÇÕES") ||
                  (!answer.find(
                    (option) => option === "NENHUMA DAS CONDIÇÕES"
                  ) &&
                    answer.length > 0 &&
                    ans === "NENHUMA DAS CONDIÇÕES")
                }
              />
              <label
                className="form-check-label"
                htmlFor={`${e.title}${index}`}
              >
                {ans}
              </label>
            </div>
          );
        } else
          return (
            <div className="form-check" key={`idRadio1-${index}`}>
              <input
                className="form-check-input"
                type="radio"
                name="exampleRadios"
                defaultChecked={answer && answer === ans ? true : false}
                id={`idRadio${index}`}
                value="option2"
                onClick={() => {
                  formatAnswerRadio(ans);
                }}
              />
              <label className="form-check-label" htmlFor={`idRadio${index}`}>
                {ans}
              </label>
            </div>
          );
      })}

      <div style={{ width: "100%", textAlign: "center" }}>
        <a>{stepQuestions + 1} / 6</a>
      </div>

      <div className="div-profuse-btn-actions">
        <a
          className="a-profuse-btn-action"
          style={{ backgroundColor: stepQuestions > 0 ? "#645268" : "#b3b3b3" }}
          onClick={() => {
            if (stepQuestions > 0) {
              setStepQuestions(stepQuestions - 1);
            }
          }}
        >
          <i className="bx bx-arrow-back" style={{ marginRight: 5 }} />
          Voltar
        </a>
        <a
          className="a-profuse-btn-action"
          style={{
            backgroundColor:
              answer.length > 0 && !loadingSend ? "#645268" : "#b3b3b3",
          }}
          onClick={() => {
            if (answer.length > 0 && !loadingSend) {
              if (stepQuestions === 5) {
                sendData();
              } else {
                setStepQuestions(stepQuestions + 1);
              }
            }
          }}
        >
          {loadingSend ? (
            "Carregando"
          ) : (
            <>
              Avançar
              <i className="bx bx-right-arrow-alt" style={{ marginLeft: 5 }} />
            </>
          )}
        </a>
      </div>
    </>
  );
}

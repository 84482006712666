import React from "react";
import "./styled.css"

export default function Product(props) {
  const { data } = props;

  return (
    <>
      <div className="div-profuse-product" style={{ padding: 10 }}>
        <img src={data.img} className="img-profuse-product" />

        <a className="a-profuse-product">{data.name}</a>
      </div>
    </>
  );
}

export const questions = [
  {
    title: "QUAL É SEU TIPO DE PELE?",
    type: "one",
    answers: ["NORMAL", "SECA", "OLEOSA", "MISTA", "SENSÍVEL"],
  },
  {
    title: "QUAL É SUA PRINCIPAL PREOCUPAÇÃO?",
    type: "one",
    answers: [
      "RUGAS E LINHAS FINAS",
      "MANCHAS / MELASMA",
      "OLHEIRAS",
      "CONTROLE DA OLEOSIDADE",
      "FLACIDEZ DA PELE",
      "CUIDADOS CORPORAIS",
    ],
  },
  {
    title:
      "VOCÊ FEZ A POUCO TEMPO OU COSTUMA FAZER PROCEDIMENTOS ESTÉTICOS FACIAIS?",
    type: "one",
    answers: ["SIM", "NÃO"],
  },
  {
    title: "VOCÊ TEM ALGUMA DAS CONDIÇÕES ESPECIAIS LISTADAS ABAIXO?",
    type: "multi",
    answers: [
      "DERMATITE ATÓPICA",
      "ROSÁCEA",
      "DIABETES",
      "NENHUMA DAS CONDIÇÕES",
    ],
  },
  {
    title: "VOCÊ PERCEBE UM AUMENTO DE QUEDA CAPILAR OU UNHAS FRACAS?",
    type: "one",
    answers: ["SIM", "NÃO"],
  },
  {
    title: "VOCÊ USA MAQUIAGEM?",
    type: "one",
    answers: ["SIM", "NÃO"],
  },
];

import React, { useEffect, useState } from "react";
import { products } from "./products";
// gerador de imagem
import html2canvas from "html2canvas";
// componentes
import Product from "./product";

export default function PageProducts(props) {
  const { allAnswer, user } = props;
  const [productList, setProductList] = useState([]);
  // imagem para baixar
  const [img, setImg] = useState(null);

  // const data = {
  //   pele: ["OLEOSA"],
  //   preocupacao: ["CUIDADOS CORPORAIS"],
  //   estetico: ["SIM"],
  //   condicoes: ["DERMATITE ATÓPICA", "ROSÁCEA", "DIABETES"],
  //   capilar: ["SIM"],
  //   maquiagem: ["SIM"],
  // };

  function getImage() {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      setImg(canvas.toDataURL("image/jpeg"));
    });
  }

  function getProducts() {
    var productsSelecteds = [];

    products.map((e, i) => {
      var addProduct = true;
      var addProductOr = false;

      for (const [keyProduct, valueProduct] of Object.entries(e)) {
        for (const [key, value] of Object.entries(allAnswer)) {
          if (keyProduct === key) {
            if (e.type === "doubleField") {
              var search = null;
              if (keyProduct === "pele") {
                valueProduct.map((prd) => {
                  search = value.find((choice) => choice === prd);
                });
              } else {
                search = value.find(
                  (prod) => prod === valueProduct || valueProduct === "TODOS"
                );
              }

              if (search) addProductOr = true;
            } else {
              var search = value.find(
                (prod) => prod === valueProduct || valueProduct === "TODOS"
              );
              if (e.type && e.type === "mandatoryNecessity") {
                var seachMandatory = allAnswer.preocupacao.find(
                  (prod) => prod === e.preocupacao
                );

                if (seachMandatory && search) {
                  addProductOr = true;
                }
              } else if (e.type && e.type === "or" && search) {
                addProductOr = true;
              } else if (!e.type && !search) {
                addProduct = false;
              } else {
              }
            }
          }
        }
      }

      if (
        (addProduct && !e.type) ||
        (addProductOr &&
          e.type &&
          (e.type === "or" ||
            e.type === "mandatoryNecessity" ||
            e.type === "doubleField"))
      ) {
        productsSelecteds.push(e);
      }
    });

    setProductList(productsSelecteds);
  }

  async function downloadFile() {
    if (user.idEvent) {
      window.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: img,
          name: "rotinaProfuse.jpeg",
        }),
        "*"
      );
      window.parent.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: img,
          name: "rotinaProfuse.jpeg",
        })
      );
    } else {
      const blob = await fetch(img).then((res) => res.blob());

      const arquivo = {
        title: "",
        files: [new File([blob], "imagem.jpg", { type: "image/jpeg" })],
      };
      await navigator.share(arquivo);
    }
  }

  useEffect(() => {
    if (
      allAnswer?.capilar &&
      allAnswer?.condicoes &&
      allAnswer?.estetico &&
      allAnswer?.maquiagem &&
      allAnswer?.pele &&
      allAnswer?.preocupacao
    )
      getProducts();
  }, [allAnswer]);

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("capture")) {
        getImage();
      }
    }, 500);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        id="capture"
        className="div-profuse-card"
        style={{
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <h4 style={{ marginBottom: 20 }}>
          {user?.name ? `${user.name},` : ""} Conheça sua rotina
        </h4>
        {productList.length > 0 ? (
          productList.map((e, i) => (
            <div key={i} style={{ width: "100%" }}>
              <Product data={e} />
            </div>
          ))
        ) : (
          <a>Nenhum produto para listar</a>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <a
          className="a-profuse-btn-action"
          style={{ backgroundColor: "#645268", fontSize: 15 }}
          onClick={() => window.location.reload()}
        >
          Responder Novamente
        </a>

        <a
          className="a-profuse-btn-action"
          style={{ backgroundColor: "#645268", fontSize: 15 }}
          onClick={() => downloadFile()}
        >
          Compartilhar Resultado
        </a>
      </div>
    </div>
  );
}

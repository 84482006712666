import React, { useState } from "react";
// questões
import { questions } from "./questions";
// componentes
import Question from "./question";

export default function PageQuestions(props) {
  const { submitForm, loading } = props;
  const [stepQuestions, setStepQuestions] = useState(0);
  const [allAnswer, setAllAnswer] = useState({});

  function saveAnswer(question) {
    setAllAnswer({
      ...allAnswer,
      [`question${question.index}`]: {
        title: question.title,
        answer: question.answer,
        type: question.type,
      },
    });
  }

  function sendData() {
    submitForm(allAnswer);
  }

  return (
    <>
      <div className="div-profuse-card">
        {stepQuestions === 0 && (
          <Question
            e={questions[0]}
            i={0}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
          />
        )}

        {stepQuestions === 1 && (
          <Question
            e={questions[1]}
            i={1}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
          />
        )}

        {stepQuestions === 2 && (
          <Question
            e={questions[2]}
            i={2}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
          />
        )}

        {stepQuestions === 3 && (
          <Question
            e={questions[3]}
            i={3}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
          />
        )}

        {stepQuestions === 4 && (
          <Question
            e={questions[4]}
            i={4}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
          />
        )}

        {stepQuestions === 5 && (
          <Question
            e={questions[5]}
            i={5}
            saveAnswer={saveAnswer}
            stepQuestions={stepQuestions}
            setStepQuestions={setStepQuestions}
            sendData={sendData}
            allAnswer={allAnswer}
            loadingSend={loading}
          />
        )}
      </div>
    </>
  );
}

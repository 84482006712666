import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

export default function GetName(props) {
  const { isOpen, toggle, setUser } = props;
  const [nameErro, setNameErro] = useState(false);

  function verifyForm(e) {
    console.log();
    if (!e.target[0].value) {
      setNameErro(true);
    } else {
      setUser({
        name: e.target[0].value,
      });

      toggle();
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyForm(e);
            }}
          >
            <label className="form-label">Seu Nome</label>
            <input className="form-control" type="text" />
            {nameErro && <a style={{ color: "red" }}>Insira seu nome</a>}

            <div className="div-profuse-btn-actions">
              <button
                type="submit"
                className="a-profuse-btn-action"
                style={{
                  backgroundColor: "#645268",
                  border: "none",
                }}
              >
                Começar
                <i
                  className="bx bx-right-arrow-alt"
                  style={{ marginRight: 5 }}
                />
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
